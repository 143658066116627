@use "./spectre.scss";
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono&family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');


select.catalog {
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  margin: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  grid-template-areas: "select";
  display: grid;
}

a.max {
  width: 100%;
}

.p-16 {
  width: auto;
  margin: 16px;
}


.min-height {
  height: 400px !important;
}

.div-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.jc-center {
  justify-content: center;
}

.option-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.f-row {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
  justify-content: center;
  max-width: 20vw;

  h5 {
    margin-left: 0.5rem;
    margin-bottom: 0px;
    margin-right: 0.5rem;
  }

}

.mt-p5 {
  margin-top: 0.5rem;
}

@media (min-width: 600px) {
  .catalog-year {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .btn-drop {
    background: transparent;
    border-color: transparent;
    color: #d95555;
  }

  .menu {
    left: auto !important;
  }

}

@media (max-width:600px) {
  .sm-dropdown {
    width: 85vw !important;
  }



}

@media (max-width: 960px) {


  .catalog-year {
    justify-content: center;
  }
}

.signature {
  width: 100vw;
  display: flex;
  margin: 0.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #696969a6;

}

span.avoid-wrap {
  display: inline-block;
}

.title-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.course {
  display: flex;
  background-color: white;
  padding: 0.25rem;
  padding-right: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  flex-direction: row;
  align-items: center !important;
  border-radius: 8px;
  justify-content: space-between;
  border: solid #d6d6d6;
  border-width: 2px;
  break-inside: avoid;
}

.link {
  word-break: break-all;
  color: #d95555;
}

.link:active,
.link:hover,
.link:focus {
  color: #cd2e2e;
  text-decoration: underline;
  cursor: pointer;
}

.btn {
  break-inside: avoid;
}

.max {
  width: 100%;
}

.chip {
  overflow: visible !important;
}

div.empty {
  background-color: transparent !important;
}

.mr-title {
  display: block;
  align-items: baseline;
  margin-left: 1rem;
  margin-bottom: 0;
}

.course div.card-right {
  display: flex;
  flex-direction: row;
  align-items: center;

}

@media print {
  .signature {
    display: none;
  }

  .hide-print {
    display: none !important;
  }
}

.course-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
}

.course p {
  margin: 10px;
}

.add-course {
  margin-right: 20px;
}

.card-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.course-listing {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-radius: 8px;
  border: solid #eeeeee;
  border-width: 2px;
  margin-bottom: 0.25rem;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
}

.course-listing.selected {
  background-color: #ff8484;
  border: solid;
  border-color: #ff5f5f;
  border-width: 2px;

  div.title {
    color: white;
  }
}

.main-title {
  font-size: 2rem;
  line-height: 1.2;
  font-family: inherit;
  font-weight: 700;
}

.btn-act {
  display: flex;
  max-width: 20vw;
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ttu {
  display: flex;
  width: 100%;
  font-size: 0.6em;
  justify-content: flex-start;
}

.button-icon {
  flex: 1;
  float: right;
  justify-content: center;
}

.btn-full {
  width: 85vw;
}

.menu-icon {
  margin-right: 0.5rem;
}